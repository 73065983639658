import { useEffect, useState } from "react";
import PopUpImageGalleryThree from "../components/magnetic-pop-up-properti-details-three";
import PropertySticky from "../components/property-sticky";
import PopUpImageGalleryTwo from "../components/magnific-pop-up-gallery-two";
import NearByProperty from "../components/near_by";
import NotFound from "../components/not-found";
import Layout from "../components/layout";
import { useParams } from "react-router-dom";
import { fetchPropertyDetailData } from "../fetch_logic/fetch_property_data";

export default function PropertyDetails() {
  const { property_name } = useParams();
  const [jsonData, setJsonData] = useState([]);
  useEffect(() => {
    const loadJsonData = async () => {
      if (!property_name) return;
      const response = await fetchPropertyDetailData(property_name);
      setJsonData(response);
    };

    loadJsonData();
  }, [property_name]);
  return (
    <Layout
    metaTitle={jsonData?.SEO?.title}
    metaDescription={jsonData?.SEO?.description}
    metaKeywords={jsonData?.SEO?.keywords}
    >
      {/* Start Main Content */}
      {jsonData || jsonData!== null ? (
        <div className="main-content">
          <div className="breadcrumb-wrap border-0 py-3">
            <div className="container">
              {/* Start Breadcrumbs */}
              <div className="row gy-2 gx-4 gx-md-5">
                <div className="col-auto">
                  {/* Start Back To Search */}
                  <a
                    href="/properties"
                    className="align-items-center d-flex fw-medium text-primary"
                  >
                    <i className="fa-solid fa-chevron-left me-1" />
                    Back To Search
                  </a>
                  {/* /. End Back To Search */}
                </div>
                <h4 className="border-start col-auto fs-18 fw-semibold mb-0 page-title text-capitalize">
                  {jsonData?.project_name}
                </h4>
              </div>
              {/* End Breadcrumbs */}
            </div>
          </div>

          {/* Start Masonry */}
          <PopUpImageGalleryThree galleryData={jsonData?.gallery} />
          {/* /. End Masonry */}
          {/* Start Sticky Property */}
          <PropertySticky
            data={[
              jsonData?.project_description,
              jsonData?.location,
              jsonData?.project_name,
              jsonData?.bedroom,
              jsonData?.tower,
              jsonData?.area,
              jsonData?.amenities,
              jsonData?.project_location,
              jsonData?.money,
              jsonData?.gallery,
              jsonData?.master_plan
            ]}
          />
          {/* End Sticky Property */}
          {/* Start Galary Items */}
          <div id="galary" className="bg-dark py-5">
            <PopUpImageGalleryTwo galleryData={jsonData?.gallery} />
            {/* /. End Galary Items */}
          </div>
          {/*Related Articles*/}
          <NearByProperty data={jsonData?.nearby_projects} />
        </div>
      ) : (
        <NotFound />
      )}

      {/* /. End Main Content */}
    </Layout>
  );
}
